'use client';
import { useAnalytics } from "../Analytics";
import { useEffect, useRef } from 'react';
import { useLogEvent, EventTypes } from '@/app/hooks/useLogEvent';

export interface StepTrackerProps {
  stepName: string;
  partner: string;
}

export const StepTracker = ({ partner, stepName }: StepTrackerProps) => {
  
  const analytics = useAnalytics();
  
  useEffect(() => {
    if (!analytics) {
      return;
    }

    analytics.trackStepViewed(partner, stepName);
  }, [partner, stepName, analytics]);

  const logPageView = useLogEvent(EventTypes.PAGE_VIEW);
  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      logPageView(stepName);
      isMounted.current = true;
    }
  }, []);

  return false;
};
