'use client';
import { getPartnerSettings } from '@/lib/partners/settings/getSettings';
import { useAnalytics } from '../Analytics';
import { usePartner } from '@/lib/partners/usePartner/client';

export interface CheckboxProps extends React.HTMLProps<HTMLSpanElement> {
  id: string;
  label: string;
  rounded?: boolean;
  checked?: boolean;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  labelClassName?: string;
  checkBoxClassName?: string;
}
export const Checkbox = (props: CheckboxProps) => {
  const {
    id,
    label,
    rounded,
    checked,
    handleChange,
    labelClassName,
    checkBoxClassName,
  } = props;
  const analytics = useAnalytics();
  const partner = usePartner();
  const partnerSettings: any = getPartnerSettings(partner);

  /**** Style adjustment for v2 journey ****/
  const prepopulatedJourney = partnerSettings?.prepopulatedJourney;
  let v2LabelStyles = '';
  if (prepopulatedJourney) {
    v2LabelStyles = 'text-sm';
  }

  const showSkeleton =
    'group-[.loading]/main:animate-pulse group-[.loading]/main:border-core-3 group-[.loading]/main:bg-core-3 group-[.loading]/main:text-core-3 group-[.loading]/main:select-none';

  return (
    <div
      className={`mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem] ${checkBoxClassName} ${showSkeleton}`}
    >
      <input
        className={`group-[.loading]/main:opacity-0 checkbox relative float-left -ml-[1.5rem] mr-[6px] mt-[0.15rem] h-[1.125rem] w-[1.125rem] appearance-none ${
          rounded ? 'rounded-full' : 'rounded-[0.25rem]'
        } border-[1px] border-solid border-core-6 outline-none before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] checked:border-checkboxColour checked:bg-checkboxColour checked:before:opacity-[0.16] checked:after:absolute checked:after:-mt-0 checked:after:ml-[0.35rem] checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-l-0 checked:after:border-t-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:transition-[border-color_0.2s] focus:before:scale-100 focus:before:opacity-[0.12] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:content-[''] checked:focus:before:scale-100 checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:after:-mt-0 checked:focus:after:ml-[0.35rem] checked:focus:after:h-[0.8125rem] checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] checked:focus:after:border-l-0 checked:focus:after:border-t-0 checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent`}
        type="checkbox"
        id={id}
        name={id}
        checked={checked}
        onChange={(e) => {
          if (handleChange) {
            handleChange(e);
          }
          analytics?.trackCheckboxClick(partner, e.target.checked, id);
        }}
      />
      <label
        className={
          labelClassName ??
          `checkboxLabel inline-block pl-[0.15rem] hover:cursor-pointer select-none ${v2LabelStyles}`
        }
        htmlFor={id}
        tabIndex={0}
      >
        {label}
      </label>
    </div>
  );
};
