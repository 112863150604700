'use client';

import { useAnalytics } from '../Analytics/hooks/useAnalytics';
import { usePartner } from '@/lib/partners/usePartner/client';
import { getPartnerSettings } from '@/lib/partners/settings/getSettings';
import {
  removeErrorDisplayFlags,
  validateElement,
} from '@/lib/utils/formValidationHelpers';
import React, { useRef, cloneElement, useState, useEffect } from 'react';
import { Checkbox } from '../Checkbox';

export interface InputProps {
  type: string;
  id: string;
  errorMessage?: string;
  placeholder?: string;
  styling?: string;
  required?: boolean;
  pattern?: string;
  autoComplete?: string;
  disabled?: boolean;
  value?: string | number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  name?: string;
  currencyPrefix?: string;
  marginBottom?: string;
  noMargin?: boolean;
  popup?: boolean;
  min?: number;
  max?: number;
  customErrorPlacement?: string;
  InfoTip?: React.ReactNode;
  toggleInput?: boolean;
  infoTipTopRight?: boolean;
  toggleLabel?: string;
  toggleAction?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  toggleDefaultChecked?: boolean;
  readonly?: boolean;
  textAlignment?: string;
  useDefaultBlur?: boolean;
}

export const Input = ({
  type,
  id,
  errorMessage = '',
  placeholder,
  styling,
  disabled,
  value,
  onChange,
  onBlur,
  onFocus,
  name,
  currencyPrefix,
  marginBottom = 'mb-10',
  noMargin,
  popup = false,
  min,
  max,
  customErrorPlacement = '',
  InfoTip,
  toggleInput = false,
  infoTipTopRight = false,
  toggleLabel,
  required,
  toggleAction,
  toggleDefaultChecked = false,
  readonly = false,
  textAlignment = '',
  useDefaultBlur = true,
  ...rest
}: InputProps) => {
  const analytics = useAnalytics();
  const partner = usePartner();
  const partnerSettings: any = getPartnerSettings(partner);
  let selectedMargin = marginBottom ? marginBottom : 'mb-10';
  const inputRef = useRef<HTMLInputElement | null>(null);
  let rounded = popup ? '-md' : '-sm';
  const [inputIsDisabled, setInputIsDisabled] =
    useState<boolean>(toggleDefaultChecked);
  const prepopulatedJourney = partnerSettings?.prepopulatedJourney;

  /**** Style adjustment for v2 journey ****/
  let v2Errorstyle = '';
  let v2FontSize = '';
  let borderColour = 'border-core-6';
  if (prepopulatedJourney) {
    borderColour = 'border-core-5';
    rounded = '';
    selectedMargin = noMargin ? '' : 'mb-8';
    v2Errorstyle = 'text-sm pt-1 pr-1 w-full';
    v2FontSize = 'text-sm';
  }

  const showSkeleton =
    'group-[.loading]/main:animate-pulse group-[.loading]/main:border-core-3 group-[.loading]/main:transition-all group-[.loading]/main:duration-200 group-[.loading]/main:select-none';
  const showSkeletonInput =
    'group-[.loading]/main:text-core-3 group-[.loading]/main:bg-core-3 group-[.loading]/main:border-core-3 group-[.loading]/main:transition-all group-[.loading]/main:duration-200 group-[.loading]/main:!placeholder-core-3 group-[.loading]/main:select-none';

  let readOnlyCSS = '';
  let readOnlyCSSInput = '';
  if (readonly) {
    readOnlyCSS = '';
    readOnlyCSSInput =
      'pointer-events-none bg-core-2 border-core-4 text-core-7';
  }

  const toggleDisableInput = (e?: React.ChangeEvent<HTMLInputElement>) => {
    if (!inputIsDisabled && inputRef.current) {
      // trigger the onChange in the input
      onChange?.({
        target: { id, value: '' },
      } as React.ChangeEvent<HTMLInputElement>);
      removeErrorDisplayFlags(inputRef.current);
      //set the style of the input an label
      document.querySelector(`*[for=${id}]`)?.classList?.add('text-core-5');
    } else if (inputRef.current) {
      // remove the styles
      document.querySelector(`*[for=${id}]`)?.classList?.remove('text-core-5');
      // needs to go at the end of the call stack, otherwise the input is disable and cannot have focus
      setTimeout(() => inputRef?.current?.focus(), 0);
    }
    setInputIsDisabled(!inputIsDisabled);

    // trigger any possible checkbox action
    if (e) toggleAction?.(e);
  };

  useEffect(() => {
    if (toggleDefaultChecked && toggleInput) {
      document.querySelector(`*[for=${id}]`)?.classList?.add('text-core-5');
      document.querySelector(`#${id}`)?.classList?.add('!border-core-5');
    }
  }, []);

  return (
    <div className="relative flex-grow">
      {InfoTip && infoTipTopRight && (
        <span className="text-right z-[8] absolute right-0 -top-8 w-full">
          {cloneElement(InfoTip as React.ReactElement, {
            label: (
              <span className="inline-block w-6 h-6 rounded-full text-center border border-1 border-core-7 text-core-7 hover:border-core-8 hover:text-core-8 font-bold group-focus:bg-core-8  group-focus:text-core-1">
                i
              </span>
            ),
          })}
        </span>
      )}
      <div
        id={`for_id_${id}`}
        className={`${toggleInput ? 'mb-0' : selectedMargin} ${
          currencyPrefix
            ? `peer w-full border ${borderColour} px-4 leading-10 placeholder-core-6 placeholder-font-light rounded-sm ${
                !inputIsDisabled
                  ? 'data-[error=true]:border-error-1 data-[error=true]:bg-error-2/50 data-[error=true]:outline-error-1'
                  : ''
              } hover:cursor-text`
            : "after:clear-both after:content-[''] after:block w-full"
        } ${showSkeleton} ${readOnlyCSS} ${v2FontSize}`}
      >
        {currencyPrefix ? `${currencyPrefix}` : ''}
        <input
          readOnly={readonly}
          min={min}
          max={max}
          type={type}
          id={id}
          disabled={disabled || inputIsDisabled}
          value={value}
          className={
            styling && !currencyPrefix
              ? styling
              : currencyPrefix
                ? 'peer focus:outline-none'
                : `peer w-full border ${showSkeletonInput} ${readOnlyCSSInput} ${
                    inputIsDisabled
                      ? 'border-core-5'
                      : `${borderColour} data-[error=true]:border-error-1 data-[error=true]:bg-error-2/50 data-[error=true]:outline-error-1`
                  } leading-10 px-4 placeholder-core-6 placeholder-font-light rounded${rounded} ${textAlignment}`
          }
          data-lpignore={true}
          onBlur={(e) => {
            analytics && analytics.trackFieldFilled(partner, id, type);

            const newTarget = e?.relatedTarget;
            const isNewTargetTheToggle =
              newTarget?.classList.contains('checkboxLabel') ||
              newTarget?.classList.contains('checkbox');

            if (!isNewTargetTheToggle && useDefaultBlur) {
              validateElement(e.target, undefined, partnerSettings.country);
            }
            onBlur?.(e);
          }}
          onFocus={(e) => { onFocus?.(e); }}
          {...rest}
          placeholder={placeholder}
          onChange={onChange}
          name={name}
          ref={inputRef}
          required={!!required}
        />

        {InfoTip && !infoTipTopRight && (
          <span className="text-right z-10 relative">{InfoTip}</span>
        )}
        <div
          className={`hidden peer-data-[error=true]:block text-error-1 ${v2Errorstyle} ${
            toggleInput && errorMessage ? 'relative' : 'absolute'
          } leading-5 ${customErrorPlacement}`}
        >
          {!inputIsDisabled && errorMessage}
        </div>
      </div>
      {toggleInput && (
        <div
          className={`text-sm leading-6  mb-7 ${
            errorMessage.length > 0 ? 'pt-2' : 'pt-8'
          }`}
        >
          <Checkbox
            type="checkbox"
            handleChange={toggleDisableInput}
            id={`disable_${id}`}
            label={toggleLabel ?? ''}
            checked={toggleDefaultChecked}
          />
        </div>
      )}
    </div>
  );
};
