'use client';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { PhoneNumberUtil } from 'google-libphonenumber';
dayjs.extend(customParseFormat);

const errorAttribute: string = 'data-error';

function isValidPreviousDate(val: string, format: string) {
  if (dayjs(val, format, true).isValid()) {
    const now = dayjs();
    const date = dayjs(val, format, true);
    const year = date.year();
    const salesforceDateLimitYear = 1701;
    if (year < salesforceDateLimitYear) {
      return false;
    }
    const diff = now.diff(date);
    if (diff > 0) {
      return true;
    }
  }
  return false;
}

function isAtLeast16(val: string, format: string) {
  if (dayjs(val, format, true).isValid()) {
    const now = dayjs();
    const date = dayjs(val, format, true);
    const year = date.year();
    const salesforceDateLimitYear = 1701;

    if (year < salesforceDateLimitYear) {
      return false;
    }
    const diff = now.diff(date, 'years');
    if (diff >= 16) {
      return true;
    }
  }
  return false;
}

const revalidate = (e: Event, partnerCountry?: string) => {
  validateElement(e.target as HTMLInputElement, true, partnerCountry);
};

const getNextSibling = function (selector: string, elem?: HTMLElement | null) {
  var sibling = elem?.nextElementSibling;

  while (sibling) {
    if (sibling.matches(selector)) return sibling;
    sibling = sibling.nextElementSibling;
  }
};

const checkAndAddErrorToSelectbox = (
  el: HTMLElement,
  alreadyInErrorState?: boolean,
) => {
  const parentElement = el?.parentElement;
  if (
    parentElement?.hasAttribute('aria-label') &&
    parentElement?.classList.contains('react-dropdown-select')
  ) {
    parentElement.setAttribute(errorAttribute, 'true');
    const errorMsgEl = getNextSibling(
      '.errorMsg',
      parentElement?.parentElement,
    );

    if (errorMsgEl) {
      errorMsgEl.setAttribute(errorAttribute, 'true');
    }
  } else if (parentElement?.classList.contains('react-flexible-select')) {
    parentElement.setAttribute(errorAttribute, 'true');
    const siblingWithBorder = parentElement.querySelector('.flexible-select-control');
    if (siblingWithBorder) {
      siblingWithBorder.setAttribute(errorAttribute, 'true');
    }
  }
};

const checkAndRemoveErrorFromSelectbox = (el: HTMLElement) => {
  const parentElement = el?.parentElement;
  parentElement?.removeAttribute(errorAttribute);
  el.removeAttribute(errorAttribute);
  const errorMsgEl = getNextSibling('.errorMsg', parentElement?.parentElement);

  if (errorMsgEl) {
    errorMsgEl.removeAttribute(errorAttribute);
  }
};

const validateControllingDateElement = (event:any) => {
  const tgt = event.target;
  const dateContainer = tgt.closest('[data-date-container]');
  const dateController = dateContainer?.querySelector('[data-date-input]');
  if (dateController) { 
    validateElement(dateController as HTMLInputElement, true);
  }
}

const checkAndAddErrorToDateInput = (el: HTMLElement) => {
  if (el?.hasAttribute('data-date-input')) {
    const siblings = Array.from(el.parentElement?.children || []);
    siblings.forEach((sibling) => {
      const inputBox = sibling.querySelector('[data-date-input-box]');
      if (inputBox) {
        inputBox.setAttribute(errorAttribute, 'true');
        inputBox.removeEventListener('keyup', validateControllingDateElement);
        inputBox.addEventListener('keyup',  validateControllingDateElement);
        inputBox.removeEventListener('blur', validateControllingDateElement);
        inputBox.addEventListener('blur',  validateControllingDateElement);
      }
    });
  }
};

const removeErrorFromDateInput = (
  el: HTMLElement,
) => {
  const siblings = Array.from(el.parentElement?.children || []);
  siblings.forEach((sibling) => {
    const inputBox = sibling.querySelector('[data-date-input-box]');
    if (inputBox) {
      inputBox.removeAttribute(errorAttribute);
    }
  });
};

const addErrorDisplayFlags = (
  el: HTMLElement,
  alreadyInErrorState?: boolean,
  partnerCountry?: string,
) => {
  el.setAttribute(errorAttribute, 'true');

  if (el?.hasAttribute('data-date-input') || el?.hasAttribute('data-date-input-box')) {
    checkAndAddErrorToDateInput(el);
  } else if (!alreadyInErrorState) {
    checkAndAddErrorToSelectbox(el, alreadyInErrorState);
    el.addEventListener('keyup', (e) => revalidate(e, partnerCountry));
  }
};

const removeErrorDisplayFlags = (el: HTMLElement) => {
  if (el?.hasAttribute('data-date-input')) {
    removeErrorFromDateInput(el);
  } else {
    checkAndRemoveErrorFromSelectbox(el);
  }
  el.removeAttribute(errorAttribute);
  el.removeEventListener('keydown', revalidate);
};

const validateElement = (
  el: HTMLInputElement,
  alreadyInErrorState?: boolean,
  partnerCountry?: string,
) => {
  let elIsValid = el.checkValidity() && (!el.value || !!el.value?.trim());

  const dobValidation = el.getAttribute('data-validate-dob');
  const previousDateValidation = el.getAttribute('data-validate-previous-date');
  const telephoneValidation = el.getAttribute('data-validate-telephone-number');

  if (!elIsValid && !telephoneValidation) {
    addErrorDisplayFlags(el, el.hasAttribute(errorAttribute));
  } else if (dobValidation && previousDateValidation) {
    const bespokeValidationTestResult = isAtLeast16(
      el.value,
      previousDateValidation,
    );
    if (!bespokeValidationTestResult) {
      addErrorDisplayFlags(el, el.hasAttribute(errorAttribute));
      elIsValid = false;
    }
  } else if (previousDateValidation) {
    const bespokeValidationTestResult = isValidPreviousDate(
      el.value,
      previousDateValidation,
    );
    if (!bespokeValidationTestResult) {
      addErrorDisplayFlags(el, el.hasAttribute(errorAttribute));
      elIsValid = false;
    }
  } else if (telephoneValidation) {
    const bespokeValidationTestResult = phoneNumberValidate(
      (el as HTMLInputElement).value,
      partnerCountry,
    );
    if (!bespokeValidationTestResult) {
      addErrorDisplayFlags(el, el.hasAttribute(errorAttribute), partnerCountry);
      elIsValid = false;
    }
  }

  if (elIsValid && el.hasAttribute(errorAttribute)) {
    removeErrorDisplayFlags(el);
  }

  return elIsValid;
};

const validateForm = (
  form: HTMLFormElement,
  partnerCountry?: string,
): boolean => {
  const formEls = form.elements;

  let valid = true;
  Array.from(formEls).forEach((el) => {
    const elIsValid = validateElement(
      el as HTMLInputElement,
      undefined,
      partnerCountry,
    );

    if (!elIsValid) {
      el.setAttribute(errorAttribute, 'true');
    } else if (el.hasAttribute(errorAttribute)) {
      el.removeAttribute(errorAttribute);
    }

    if (!elIsValid && valid) {
      valid = false;
    }
  });

  return valid;
};

const scrollToFirstError = () => {
  const erroredElements = document.querySelectorAll<HTMLInputElement>(
    `*[${errorAttribute}='true']`,
  );

  if (erroredElements[0]) {
    erroredElements[0].scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
    erroredElements[0]?.focus();
  }
};

const phoneNumberValidate = (phoneNumber: string, partnerCountry?: string) => {
  const phoneUtil = PhoneNumberUtil.getInstance();
  let isValidPhoneNumber = true;
  try {
    const numberProto = phoneUtil.parse(phoneNumber, partnerCountry);
    isValidPhoneNumber = phoneUtil.isValidNumber(numberProto);
  } catch (error) {
    isValidPhoneNumber = false;
  }

  return isValidPhoneNumber;
};

export {
  validateForm,
  scrollToFirstError,
  validateElement,
  phoneNumberValidate,
  errorAttribute,
  isValidPreviousDate,
  isAtLeast16,
  removeErrorDisplayFlags,
};
